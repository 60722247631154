import apiClient from '../../axios'

export default class AddressTypesService {
  static async getRequisiteAddressTypes() {
    try {
      const response = await apiClient.get('/admin/address-types')
      return response.data.data
    } catch (error) {
      console.error('Error fetching address types:', error)
      throw error
    }
  }
}
