import apiClient from '../../axios'

export default class PaySystemsService {
  static async getPsystems(active = false, currencyId = false, targetType = 'p2p') {
    const act = active ? 'active' : ''
    const cur = currencyId ? `currency_id=${currencyId}` : ''
    let query = act.length > 0 ? act + '&' : ''
    query += cur + `target=${targetType}`
    try {
      const response = await apiClient.get(`/admin/ps?${query}`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching payment systems:', error)
      throw error
    }
  }

  static async updatePsystemSort(sortData) {
    try {
      const response = await apiClient.patch('/admin/ps/update_sort', sortData)
      return response.data.data
    } catch (error) {
      console.error('Error updating payment system sort:', error)
      throw error
    }
  }
}
