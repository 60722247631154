<template>
  <div id="app">
    <localization></localization>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Localization from '@/localization'
import Echo from 'laravel-echo'

export default {
  name: 'app',
  components: { Localization },
  computed: {
    ...mapState(['settings']),
    ...mapState('user', ['authorized']),
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
    currentRoute() {
      return this.$route.path
    },
  },
  mounted() {
    this.$store.dispatch('user/LOAD_CURRENT_ACCOUNT')
    this.$store.commit('SET_PRIMARY_COLOR', { color: this.settings.primaryColor })
    this.$store.commit('SET_THEME', { theme: this.settings.theme })
    this.$moment.tz.setDefault('Europe/Moscow')
    // this.$moment.tz.setDefault('Etc/GMT+3')

    // this.initPusher()
    // console.log('env', process.env.VUE_APP_META_URL)
  },
  watch: {
    '$store.state.settings.theme'(theme) {
      this.$store.commit('SET_THEME', { theme })
    },
    authorized(authorized) {
      if (authorized) {
        this.$store.dispatch('getCurrencies')
        this.$store.dispatch('getProjectList')
        this.$store.dispatch('getAgentList')
        this.$store.dispatch('getAccountRewardPlans')
        this.$store.dispatch('getRequisiteAddressTypes')
        this.$store.dispatch('getPaymentMethods')
        if (this.currentRoute === '/auth/login') {
          this.$router.replace(this.nextRoute)
        }
      }
    },
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  methods: {
    initPusher() {
      window.Pusher = require('pusher-js')
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_KEY,
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true,
        forceTLS: true,
        wsHost: process.env.VUE_APP_PUSHER_HOST,
        wssHost: process.env.VUE_APP_PUSHER_HOST,
        wsPort: process.env.VUE_APP_WS_PORT,
        wssPort: process.env.VUE_APP_WS_PORT,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
      })
    },
  },
}
</script>
