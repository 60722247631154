import 'ant-design-vue/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import NProgress from 'vue-nprogress'
import VueLayers from 'vuelayers'
import BootstrapVue from 'bootstrap-vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import Clipboard from 'v-clipboard'
import helpers from './helpers'
// import ECharts from 'vue-echarts'
// import { use } from 'echarts/core'

// import Echo from 'laravel-echo'
// import Pusher from 'pusher-js'

import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './localization'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowCircleDown, faArrowCircleUp, faMoneyCheck, faExternalLinkSquareAlt, faInfo,
  faMoneyCheckAlt, faPlay, faPause, faStop, faSyncAlt, faSortAmountDownAlt, faSortAmountUpAlt,
  faSmile, faFrown, faAddressCard, faTrash, faTrashAlt, faFileInvoiceDollar, faPiggyBank,
  faHandHoldingUsd, faChartBar, faChartArea, faCheckCircle, faWallet, faMinusCircle, faClock,
  faTimesCircle, faImage, faFileImage, faDonate, faUserTie, faUser, faUsers, faUserFriends, faPoo, faPoop, faBan, faDoorOpen,
  faPowerOff, faBell, faBellSlash, faComment, faSitemap, faLayerGroup, faObjectUngroup, faCoins,
} from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faWhatsappSquare, faTelegram, faViber, faSkype } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './antd'
// import './registerServiceWorker'

library.add(faArrowCircleDown, faArrowCircleUp, faMoneyCheck, faExternalLinkSquareAlt, faInfo,
  faMoneyCheckAlt, faPlay, faPause, faStop, faSyncAlt, faSortAmountDownAlt, faSortAmountUpAlt,
  faSmile, faFrown, faAddressCard, faTrash, faTrashAlt, faFileInvoiceDollar, faPiggyBank,
  faHandHoldingUsd, faChartBar, faChartArea, faCheckCircle, faMinusCircle, faWallet, faClock,
  faTimesCircle, faImage, faFileImage, faDonate, faUserTie, faUser, faUsers, faUserFriends, faPoo, faPoop, faBan, faDoorOpen,
  faPowerOff, faBell, faBellSlash, faComment, faSitemap, faLayerGroup, faObjectUngroup, faCoins)

library.add(faWhatsapp, faWhatsappSquare, faTelegram, faViber, faSkype)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueMoment, { moment })
Vue.use(BootstrapVue)
Vue.use(VueLayers)
// Vue.use(Echo)
// Vue.use(Pusher)
Vue.use(helpers)
Vue.use(Clipboard)
Vue.use(NProgress)
Vue.use(VuePageTitle, {
  prefix: 'LanPay',
  router,
})

Vue.config.productionTip = true
const nprogress = new NProgress({ parent: 'body' })

new Vue({
  router,
  store,
  nprogress,
  i18n,
  render: h => h(App),
}).$mount('#app')
