import apiClient from '../../axios'

export default class PaymentsService {
  static async getClientPayments(data) {
    try {
      const response = await apiClient.get('/admin/payments', { params: data })
      return response
    } catch (error) {
      console.error('Error fetching client payments:', error)
      throw error
    }
  }

  static async confirmClientPayment(paymentId) {
    try {
      const response = await apiClient.patch(`/admin/payments/${paymentId}/confirm`)
      return response
    } catch (error) {
      console.error('Error confirming client payment:', error)
      throw error
    }
  }

  static async getClientPaymentStatusHistory(paymentId) {
    try {
      const response = await apiClient.get(`/admin/payments/${paymentId}/history`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching client payment status history:', error)
      throw error
    }
  }

  static async getClientPaymentWebhooks(paymentId) {
    try {
      const response = await apiClient.get(`/admin/payments/${paymentId}/webhooks`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching client payment webhooks:', error)
      throw error
    }
  }

  static async getClientPaymentRecipients(paymentId) {
    try {
      const response = await apiClient.get(`/admin/payments/${paymentId}/recipients`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching client payment recipients:', error)
      throw error
    }
  }

  static async makeNewBuyOrder(clientId, amount) {
    try {
      const data = { client_id: clientId, amount: amount }
      const response = await apiClient.post('/admin/payments/make', data)
      return response.data.data
    } catch (error) {
      console.error('Error making new buy order:', error)
      throw error
    }
  }

  static async newBuyOrderLookupClient(clientStr) {
    try {
      const response = await apiClient.get('/admin/payments/make/lookup-client', { params: { search: clientStr } })
      return response.data.data
    } catch (error) {
      console.error('Error looking up new buy order client:', error)
      throw error
    }
  }

  static async sendPaymentWebhook(paymentId, status = null) {
    try {
      const data = { payment_id: paymentId, status: status }
      const response = await apiClient.post('/admin/payments/send_webhook', data)
      return response
    } catch (error) {
      console.error('Error sending payment webhook:', error)
      throw error
    }
  }

  static async exportPaymentCSVFile(payload) {
    try {
      const { data } = await apiClient.post('/admin/payments/analytics/report', payload)
      return data
    } catch (error) {
      console.error('Error downloading CSV file:', error)
      throw error
    }
  }

  static async getPaymentMethods() {
    try {
      const { data } = await apiClient.get('/admin/payment-methods')
      return data
    } catch (error) {
      console.error('Error fetching payment methods', error)
      throw error
    }
  }
}
