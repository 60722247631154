import apiClient from '../../axios'

export default class ProjectsService {
  static async getProjects(activeOnly = false, short = false) {
    const query = `active=${activeOnly}&short=${short}`
    try {
      const response = await apiClient.get(`/admin/projects?${query}`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching projects:', error)
      throw error
    }
  }

  static async getProjectsDeposits(reqData) {
    try {
      const response = await apiClient.get('/admin/projects/deposits', { params: reqData })
      return response
    } catch (error) {
      console.error('Error fetching projects deposits:', error)
      throw error
    }
  }

  static async projectToggleWithdrawalEnabled(projectId) {
    try {
      const response = await apiClient.patch(`/admin/projects/${projectId}/settings/toggle_withdrawals`)
      return response.data.data
    } catch (error) {
      console.error('Error toggling withdrawal enabled for project:', error)
      throw error
    }
  }

  static async getProjectRatesByCurrency(currencyId) {
    try {
      const response = await apiClient.get(`/admin/project-rates?currency_id=${currencyId}`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching projects by currency :', error)
      throw error
    }
  }

  static async updateProjectRates(projectId, params) {
    try {
      const response = await apiClient.patch(`/admin/project-rates/${projectId}`, { ...params })
      return response.data.data
    } catch (error) {
      console.error('Error updating project rates:', error)
      throw error
    }
  }
}
