import apiClient from '../../axios'

export default class SettingsService {
  static async getSettings() {
    try {
      const response = await apiClient.get('/admin/settings')
      return response.data.data
    } catch (error) {
      console.error('Error fetching settings:', error)
      throw error
    }
  }

  static async setSetting(settingName, value) {
    try {
      const response = await apiClient.get(`/admin/settings/set/${settingName}/${value}`)
      return response
    } catch (error) {
      console.error('Error setting value:', error)
      throw error
    }
  }
}
