import apiClient from '../../../axios'

export default class AccountsRewardsService {
  static async getAccountRewards(accountId, month, reqData) {
    try {
      const response = await apiClient.get(`/admin/accounts/${accountId}/rewards?month=${month}`, { params: reqData })
      return response
    } catch (error) {
      console.error('Error fetching account rewards:', error)
      throw error
    }
  }

  static async getAccountRewardTypes(active) {
    try {
      const act = active ? '?active' : ''
      const response = await apiClient.get(`/admin/accounts/reward_plan${act}`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching account reward types:', error)
      throw error
    }
  }
}
