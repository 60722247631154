import apiClient from '../../axios'

export default class AccountsService {
  static async getAccounts(reqData) {
    try {
      const response = await apiClient.get('/admin/accounts', { params: reqData })
      return response
    } catch (error) {
      console.error('Error fetching accounts:', error)
      throw error
    }
  }

  // For getting global agents list for dropdowns, filters in different pages
  static async getAccountList() {
    try {
      const params = {
        short: true,
        statuses: 'active',
      }
      const response = await apiClient.get('/admin/accounts', { params })
      return response.data.data
    } catch (error) {
      console.error('Error fetching account list:', error)
      throw error
    }
  }

  static async getAccountStats(accountId, month) {
    try {
      const response = await apiClient.get(`/admin/accounts/${accountId}/stats`, {
        params: { month },
      })
      return response
    } catch (error) {
      console.error('Error fetching account stats:', error)
      throw error
    }
  }

  static async restoreAccount(accountId) {
    try {
      const response = await apiClient.patch(`/admin/accounts/${accountId}/restore`)
      return response.data.data
    } catch (error) {
      console.error('Error restore account:', error)
      throw error
    }
  }

  static async exportCSVAccountAnalytics(payload) {
    try {
      const { data } = await apiClient.post('/admin/accounts/analytics/report', payload)
      return data
    } catch (error) {
      console.error('Error downloading CSV file:', error)
      throw error
    }
  }
}
