<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft" v-if="runBaseRate">
    <div :class="$style.dropdown">
      <span class="d-none d-xl-inline small text-gray-5">{{ $t('topBar.baseRate.title') }}</span>
      <span class="d-none d-xl-inline text">{{ $t('topBar.baseRate.rub') }}: {{runBaseRate}}</span>
    </div>
    <a-menu slot="overlay">
      <a-menu-item-group :title="$t('topBar.baseRate.others')">
        <template v-for="currency in othersCurrencies">
          <a-menu-item v-if="currency.rate !== null" :key="currency.name">
            <span class="small text-gray-5">{{ currency.name }} {{ currency.rate?.base }}</span>
          </a-menu-item>
        </template>
      </a-menu-item-group>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'menu-left',
  computed: {
    ...mapGetters(['activeCurrencies']),
    runBaseRate() {
      const currency = this.activeCurrencies.find(el => el.name === 'RUB')
      if (currency) {
        return currency.rate?.base
      }
      return null
    },
    othersCurrencies() {
      const currencies = this.activeCurrencies.filter(el => el.name !== 'RUB')
      if (currencies) {
        return currencies
      }
      return []
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module";
</style>
