import apiClient from '../../axios'

export default class CurrenciesService {
  static async getCurrencies(active = false) {
    try {
      const query = active ? '?no_crypto&active' : '?no_crypto'
      const response = await apiClient.get(`/admin/currencies${query}`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching currencies:', error)
      throw error
    }
  }

  static async createCurrency(currencyData) {
    try {
      const response = await apiClient.post('/admin/currencies', currencyData)
      return response
    } catch (error) {
      console.error('Error creating currency:', error)
      throw error
    }
  }

  static async updateCurrency(currencyId, currencyData) {
    try {
      const response = await apiClient.patch(`/admin/currencies/${currencyId}`, currencyData)
      return response
    } catch (error) {
      console.error('Error updating currency:', error)
      throw error
    }
  }

  static async deleteCurrency(currencyId) {
    try {
      const response = await apiClient.delete(`/admin/currencies/${currencyId}`)
      return response
    } catch (error) {
      console.error('Error deleting currency:', error)
      throw error
    }
  }
}
