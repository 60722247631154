var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout-sider',{class:{
      [_vm.$style.menu]: true,
      [_vm.$style.white]: _vm.settings.menuColor === 'white',
      [_vm.$style.gray]: _vm.settings.menuColor === 'gray',
      [_vm.$style.dark]: _vm.settings.menuColor === 'dark',
      [_vm.$style.unfixed]: _vm.settings.isMenuUnfixed,
      [_vm.$style.shadow]: _vm.settings.isMenuShadow,
    },attrs:{"width":_vm.settings.leftMenuWidth,"collapsible":_vm.settings.isMobileView ? false : true,"collapsed":_vm.settings.isMenuCollapsed && !_vm.settings.isMobileView}},[_c('div',{class:_vm.$style.menuOuter,style:({
        width: _vm.settings.leftMenuWidth + 'px'
      })},[_c('div',{class:_vm.$style.logoContainer},[_c('div',{class:_vm.$style.logo},[_c('img',{staticClass:"mr-2",attrs:{"src":"resources/images/logo.png","alt":"Lanpay","width":"28"}}),_c('div',{class:_vm.$style.name},[_c('span',{staticClass:"text-gray-6"},[_vm._v(_vm._s(_vm.settings.logo))])])]),(!_vm.settings.isMobileView)?_c('a-icon',{staticClass:"text-gray-5",style:({
            marginLeft: _vm.settings.isMenuCollapsed ? '-110px': '32px',
            fontSize: '24px'
            }),attrs:{"type":_vm.settings.isMenuCollapsed ? 'menu-unfold' : 'menu-fold'},on:{"click":_vm.onCollapse}}):_vm._e()],1),_c('div',{class:_vm.$style.menu,style:({
          height: 'calc(100% - 64px)',
        })},[_c('a-menu',{class:[_vm.$style.navigation, 'h-100'],attrs:{"forceSubMenuRender":"","inlineCollapsed":_vm.settings.isMobileView ? false : _vm.settings.isMenuCollapsed,"mode":'inline',"selectedKeys":_vm.selectedKeys,"openKeys":_vm.openKeys,"inlineIndent":15,"theme":"light"},on:{"update:openKeys":function($event){_vm.openKeys=$event},"update:open-keys":function($event){_vm.openKeys=$event},"click":_vm.handleClick,"openChange":_vm.handleOpenChange}},[_vm._l((_vm.menuData),function(item,index){return [(((item.roles && _vm.userHasAnyRole(_vm.user, item.roles)) || (_vm.userCanView(_vm.user, item.key))) && !item.hidden)?[(item.category)?_c('a-menu-item-group',{key:index},[_c('template',{slot:"title"},[_vm._v(_vm._s(item.title))])],2):_vm._e(),(!item.children && !item.category)?_c('item',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e(),(item.children)?_c('sub-menu',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e()]:_vm._e()]})],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }