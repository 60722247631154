import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import ps from './ps'
import CurrenciesService from '../services/api/currencies/currencies.service'
import AccountsRewardsService from '../services/api/accounts/rewards/accounts.rewards.service'
import ProjectsService from '../services/api/projects/projects.service'
import AddressTypesService from '../services/api/address-types/address.types.service'
import PaySystemsService from '../services/api/pay-systems/pay.systems.service'
import AccountsService from '../services/api/accounts/accounts.service'
import PaymentsService from '../services/api/payments/payments.service'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    settings,
    ps,
  },
  state: {
    currencies: [],
    psystems: [],
    projectList: [],
    agentList: [],
    paymentMethods: [],
    accountRewardPlans: [],
    requisiteAddressTypes: [],
  },
  mutations: {
    setCurrencies (state, currencies) {
      state.currencies = currencies
    },
    setPsystems (state, psystems) {
      state.psystems = psystems
    },
    setProjectList (state, projects) {
      state.projectList = projects
    },
    setAgentList (state, agents) {
      state.agentList = agents
    },
    setPaymentMethods (state, methods) {
      state.paymentMethods = methods
    },
    setAccountRewardPlans (state, plans) {
      state.accountRewardPlans = plans
    },
    setRequisiteAddressTypes(state, addressTypes) {
      state.requisiteAddressTypes = addressTypes
    },
    addPsystem (state, psystem) {
      state.psystems.unshift(psystem)
    },
    updatePsystem(state, psystem) {
      const ind = state.psystems.findIndex(obj => obj.id === psystem.id)
      state.psystems[ind] = psystem
    },
    deletePsystem(state, psystemId) {
      const ind = state.psystems.findIndex(obj => obj.id === psystemId)
      state.psystems.splice(ind, 1)
    },
  },
  actions: {
    //
    getCurrencies({ commit, dispatch }) {
      return CurrenciesService.getCurrencies().then(
        response => {
          commit('setCurrencies', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getPsystems({ commit, dispatch }) {
      return PaySystemsService.getPsystems().then(
        response => {
          commit('setPsystems', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getRequisiteAddressTypes({ commit, dispatch }) {
      return AddressTypesService.getRequisiteAddressTypes().then(
        response => {
          commit('setRequisiteAddressTypes', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getProjectList({ commit, dispatch }) {
      return ProjectsService.getProjects(false, true).then(
        response => {
          commit('setProjectList', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getPaymentMethods({ commit, dispatch }) {
      return PaymentsService.getPaymentMethods().then(
        response => {
          const data = Object.keys(response.data).map(key => ({
            key,
            name: response.data[key],
          }))
          commit('setPaymentMethods', data)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getAgentList({ commit, dispatch }) {
      return AccountsService.getAccountList().then(
        response => {
          commit('setAgentList', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getAccountRewardPlans({ commit, dispatch }) {
      return AccountsRewardsService.getAccountRewardTypes().then(
        response => {
          commit('setAccountRewardPlans', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
  },
  getters: {
    currencies: state => state.currencies.sort((a, b) => b.is_active - a.is_active),
    activeCurrencies: state => state.currencies.filter(obj => obj.is_active === true),
    psystems: state => state.psystems,
    activePsystems: state => state.psystems.filter(obj => obj.is_active === true),
    projectList: state => state.projectList.sort((a, b) => b.is_active - a.is_active),
    agentList: state => state.agentList,
    activeProjectList: state => state.projectList.filter(obj => obj.is_active === true),
    accountRewardPlans: state => state.accountRewardPlans,
    paymentMethods: state => state.paymentMethods,
    requisiteAddressTypes: state => state.requisiteAddressTypes,
  },
})
